
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        

















































































































































































































.title {
  max-width: rem(128);
}
